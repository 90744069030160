import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import useDoHit from 'api/mutations/punch'
import withIdleTimer from 'lib/HOC/withIdleTimer'
import useStartQuickstart from 'lib/hooks/useStartQuickstart'
import { useWorkoutActions } from 'store/workout/selectors'
import Main from 'layout/main'
import Button from 'components/button'
import LoginBtn from 'components/login/login-btn'
import ConnectionIndicator from 'components/connection-indicator/connection-indicator'

const Home = () => {
  useStartQuickstart() // Quickstart workout starts when user punches the bag (or when developer clicks "HIT THE BAG" button on this page)
  const { resetWorkout } = useWorkoutActions()
  const doHit = useDoHit()

  useEffect(() => {
    resetWorkout()
  }, [resetWorkout])

  // client wanted to refresh the page every 5 minutes to "refresh" the code
  // temporary taken out as we deploy a fix to the useAddHit hook
  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload()
    }, 5 * 60 * 1000) // 5 minutes

    return () => clearInterval(interval)
  }, [])

  return (
    <Main className="nextround-home">
      <div className="home">
        <div className="home__header">
          <div className="home__header__logo" />
          <div className="home__header__btns">
            <ConnectionIndicator />
            <LoginBtn />
          </div>
        </div>
        <div>
          <div onClick={doHit} className="home__cta">
            <h1>HIT THE BAG</h1>
            <div className="home__cta__subTitle">To start Quickstart</div>
          </div>
        </div>
        <div className="home__button">
          <div className="home__button__subTitle">Improve your skills</div>
          <Link to="/workouts">
            <Button color="primary">
              <h3>Go to select workout</h3>
            </Button>
          </Link>
        </div>
      </div>
    </Main>
  )
}

export default withIdleTimer(Home)
