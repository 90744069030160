import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { gql, useSubscription } from '@apollo/client'

import { INRWorkoutSessionHit } from 'interfaces/workout'
import { pathsObj } from 'lib/constants/paths'
import { getInitQuickstartSession } from 'lib/initial'
import { useWorkoutActions } from 'store/workout/selectors'
import useUpdateExerciseStarted from './useUpdateExerciseStarted'

const HIT = gql`
  subscription quickstartHit {
    hit {
      force
    }
  }
`

const useStartQuickstart = () => {
  const navigate = useNavigate()
  const { data } = useSubscription<{ hit: INRWorkoutSessionHit }>(HIT)
  const { updateWorkoutSession } = useWorkoutActions()
  const updateExerciseStarted = useUpdateExerciseStarted()

  const startQuickstart = useCallback(() => navigate(pathsObj.freestyle.freestyleActive), [navigate])
  const initQuickstartSession = getInitQuickstartSession()

  useEffect(() => {
    if (data && data.hit) {
      updateWorkoutSession(initQuickstartSession)
      updateExerciseStarted()
      startQuickstart()
    }
  }, [data, initQuickstartSession, startQuickstart, updateWorkoutSession])
}

export default useStartQuickstart
